import React from "react";
import "./Contact.scss";
import bioImage from "../../assets/about/BioHeadshot2022.jpg";
import about1 from "../../assets/about/about1.jpeg";
import about2 from "../../assets/about/about2.jpeg";
import boxIcon from "../../assets/about/squareIcon.png";

function Contact() {
  return (
    <div className="contactContainer">
      <div className="bioSection">
        <p className="bio-text">
          Jocelyn Gallegos is a queer non-binary Mexican-American Los Angeles
          based filmmaker with an emphasis in cinematography and creative
          direction. They attended California State University, Los Angeles
          where they received a Bachelor of Arts in Television, Film and Media
          Studies with Narrative Production pathway. Gallegos is passionate
          about stories that explore social and psychological realities across
          themes of intersectionality between being BIPOC and Queer, mental
          health, and spirituality. Their creative style is grounded in
          romanticism and fantastical realism guided by slowness and symmetry
          and motivated by dynamic lighting and movement.{" "}
        </p>
        <div className="bioImageContainer">
          <div className="rowBioImages">
            <img className="bioImage" src={about1} alt="jocelyn" />
            <img className="bioImage" src={bioImage} alt="jocelyn" />
          </div>
          <img
            className="bioImage"
            id="smallBioPic"
            src={about2}
            alt="jocelyn"
          />
        </div>
      </div>
      <div className="contact-info">
        <a href={"tel:12092775139"}>209.277.5139</a>
        <a href={"mailto:jocelyngallegos628@gmail.com"}>
          jocelyngallegos628@gmail.com
        </a>
        <div>
          <img className="logo" src={boxIcon} alt="square" />
        </div>
      </div>
    </div>
  );
}

export default Contact;
