import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import Landing from "../pages/Landing/Landing";
import Header from "../components/Header/Header";
import VideoGallery from "../pages/VideoGallery/VideoGallery";
import Video from "../pages/Video/Video";
import Contact from "../pages/Contact/Contact";
import Commercial from "../pages/Commercial/Commercial";
import Lifestyle from "../pages/Lifestyle/Lifestyle";
import Login from "../pages/Login/Login";
import Miata from "../pages/Miata/Miata";
import Footer from "../components/Footer/Footer";

function Routing() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/reel">
          <Video />
        </Route>
        <Route path="/narratives">
          <VideoGallery page="narratives" />
        </Route>
        <Route path="/musicvideos">
          <VideoGallery page="musicvideos" />
        </Route>
        <Route path="/documentary">
          <VideoGallery page="documentary" />
        </Route>
        <Route path="/video">
          <Video />
        </Route>
        <Route path="/about">
          <Contact />
        </Route>
        <Route path="/commercial">
          <Commercial />
        </Route>
        <Route path="/lifestyle">
          <Lifestyle />
        </Route>
        <Route path="/studiolighting">
          <Contact />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/miata">
          <Miata />
        </PrivateRoute>
        <Route path="/">
          <Landing />
        </Route>
        <Route>
          <p>404 not found</p>
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default Routing;
