import React, {useEffect, useState} from 'react';
import {
  Route,
  Redirect,
} from "react-router-dom";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const [isAuthenticated, setAuth] = useState(false)
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    getAuth()
  })

  const getAuth = async () => {
    try{
      const auth = await localStorage.getItem('auth')
      if(!auth) {
        setAuth(false)
        setLoading(false)
        return;
      }
      const authParsed = JSON.parse(auth);
      const { user } = authParsed;

      if(authParsed && user){
        const isAuth = authParsed.user.uid;
        setAuth(isAuth)
        setLoading(false)
      } else {
        setAuth(false)
        setLoading(false)

      }
    }catch(e){
      console.log(e)
    }
  }

  return (
    <>
    {isLoading ||
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/narratives",
              state: { from: location }
            }}
          />
        )
      }
    />
    }
    </>
  );
}

export default PrivateRoute;