import React, { useEffect } from "react";
import "./Login.scss";
import firebase from '../../config/firebase';
import { useHistory } from "react-router-dom";
const firebaseui = require('firebaseui');
var ui = new firebaseui.auth.AuthUI(firebase.auth());

function Login() {
  let history = useHistory();

  useEffect(() => {
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          localStorage.setItem('auth', JSON.stringify(authResult))
          history.push('/miata')
          return false;
        },
        uiShown: function() {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById('loader').style.display = 'none';
        }
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      // signInFlow: 'popup',
      // signInSuccessUrl: 'http://localhost:3000/miata',
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
    };
    ui.start('#firebaseui-auth-container', uiConfig);
  }, [])
  return (
    <>
      <div id="firebaseui-auth-container" />
      <div id="loader">Loading...</div>
    </>
  );
}

export default Login;
