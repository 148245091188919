import React from "react";
import "./Lifestyle.scss";

// const customThumbnails = {
//   buzzReel,
// };

function Lifestyle() {
  return (
    <div className="commercialContainer">
      <div className="comSection" style={{ marginBottom: "5rem" }}>
        <p className="cTitle">
          DIANDRA MARIZET (INTERSECTIONAL ENVIRONMENTALIST) - VIDEOGRAPHY
        </p>
        <div className="cVideos">
          <Video
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/DIANDRA%20MARIZET%20(INTERSECTIONAL%20ENVIRONMENTALIST)%20-%20VIDEOGRAPHY%2F1.%20Slow%20Moments.png?alt=media"
            className="cVid"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/DIANDRA%20MARIZET%20(INTERSECTIONAL%20ENVIRONMENTALIST)%20-%20VIDEOGRAPHY%2FDIANDRA%20MARIZET%201.%20Slow%20Moments%20(COMPRESSED).mp4?alt=media"
          />
          <Video
            className="cVid"
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/DIANDRA%20MARIZET%20(INTERSECTIONAL%20ENVIRONMENTALIST)%20-%20VIDEOGRAPHY%2F2.%20Book%20Reel.jpg?alt=media"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/DIANDRA%20MARIZET%20(INTERSECTIONAL%20ENVIRONMENTALIST)%20-%20VIDEOGRAPHY%2FDIANDRA%20MARIZET%202.%20Book%20Reel%20(COMPRESSED).mp4?alt=media"
          />
          <Video
            className="cVid"
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/DIANDRA%20MARIZET%20(INTERSECTIONAL%20ENVIRONMENTALIST)%20-%20VIDEOGRAPHY%2F3.%20Dia%20De%20Los%20Muertos.png?alt=media"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/DIANDRA%20MARIZET%20(INTERSECTIONAL%20ENVIRONMENTALIST)%20-%20VIDEOGRAPHY%2FDIANDRA%20MARIZET%203.%20Dia%20De%20Los%20Muertos%20(COMPRESSED).mp4?alt=media"
          />
        </div>
      </div>
    </div>
  );
}

const Video = ({ src, ...props }) => (
  <video controls {...props}>
    <source src={src} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
);

export default Lifestyle;
