import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDD2iLa-PfPIonSWYO3uwE45EICffqt9Gs",
  authDomain: "jocelyns-portfolio.firebaseapp.com",
  databaseURL: "https://jocelyns-portfolio.firebaseio.com",
  projectId: "jocelyns-portfolio",
  storageBucket: "jocelyns-portfolio.appspot.com",
  messagingSenderId: "879958306516",
  appId: "1:879958306516:web:07e2e2148ff6c1aa229541",
  measurementId: "G-5VWRH64W8V"
};

firebase.initializeApp(firebaseConfig)

export default firebase;