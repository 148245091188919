import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "./Header.scss";

const headerPages = {
  "/narratives": true,
  "/musicvideos": true,
  "/commercial": true,
  "/lifestyle": true,
  "/studiolighting": true,
  "/about": true,
  "/documentary": true,
  "/video": true,
  "/reel": true,
};
function Header() {
  const location = useLocation();
  const { pathname } = location;
  const showHeader = pathname && pathname in headerPages;

  return <>{showHeader && <Navbar pathname={pathname} />}</>;
  // return (
  //   <>
  //     {showHeader && (
  //       <div className="header">
  //         <h3 className="header-name">Jocelyn Gallegos</h3>
  //         <h4 className="sub-name">Cinematographer</h4>
  //         <NavLink
  //           exact
  //           className="option"
  //           activeClassName="activeOption"
  //           to="/narratives"
  //         >
  //           Narrative
  //         </NavLink>
  //         <NavLink
  //           exact
  //           className="option"
  //           activeClassName="activeOption"
  //           to="/musicvideos"
  //         >
  //           Music Video
  //         </NavLink>
  //         <NavLink
  //           exact
  //           className="option"
  //           activeClassName="activeOption"
  //           to="/commercial"
  //         >
  //           Commercial
  //         </NavLink>
  //         <NavLink
  //           exact
  //           className="option"
  //           activeClassName="activeOption"
  //           to="/documentary"
  //         >
  //           Documentary
  //         </NavLink>
  //         <NavLink
  //           exact
  //           className="option"
  //           activeClassName="activeOption"
  //           to="/contact"
  //         >
  //           Contact
  //         </NavLink>
  //       </div>
  //     )}
  //   </>
  // );
}

export default Header;
