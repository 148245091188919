import React from "react";
import "./Laurels.scss";

import Arthouse from "../../assets/laurels/Arthouse.png";
import BSC from "../../assets/laurels/BSC.png";
import Chicago from "../../assets/laurels/Chicago.png";
import LACAFinalist from "../../assets/laurels/LACAFinalist.png";
import Official_Selections from "../../assets/laurels/Official_Selections.png";
import SFFinalist from "../../assets/laurels/SFFinalist.png";

const map = {
  bamboo: [
    LACAFinalist,
    Official_Selections,
    BSC,
    Arthouse,
    Chicago,
    SFFinalist,
  ],
};

function Laurels({ name }) {
  return (
    <div className="laurelContainer">
      {map[name].map((image, i) => (
        <Laurel key={i} src={image} />
      ))}
    </div>
  );
}

export default Laurels;

const Laurel = ({ src }) => <img src={src} className="laurel" alt="laurel" />;
