import React, { useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import "./Navbar.scss";

const Navbar = ({ pathname }) => {
  const [mobile, setMobile] = useState(true);
  const history = useHistory();
  return (
    <div className={`navbar ${!mobile ? "responsive" : ""}`}>
      <div className="name-block" onClick={() => history.push("/reel")}>
        <p className="nav-name">Jocelyn Gallegos</p>
        <p className="sub-nav">&nbsp;|&nbsp;Cinematographer</p>
      </div>
      <div className="tabs">
        <NavLink exact activeClassName="activeOption" to="/reel">
          Reel
        </NavLink>
        <NavLink exact activeClassName="activeOption" to="/narratives">
          Narrative
        </NavLink>
        <NavLink exact to="/musicvideos">
          Music Video
        </NavLink>
        <NavLink exact to="/commercial">
          Commercial
        </NavLink>
        <NavLink exact to="/lifestyle">
          Lifestyle
        </NavLink>
        {/* <div className="dropdown">
          <button
            className={`dropbtn`}
            onClick={() => history.push("/commercial")}
          >
            Commercial
          </button>
          <div className="dropdown-content">
            <NavLink exact $primary-color to="/studiolighting">
              ^ Studio Lighting
            </NavLink>
          </div>
        </div> */}
        <NavLink exact to="/documentary">
          Documentary
        </NavLink>
        <NavLink exact to="/about">
          About
        </NavLink>
      </div>
      <MdMenu
        className="hamburger"
        size={20}
        onClick={() => setMobile(!mobile)}
      />
    </div>
  );
};

export default Navbar;
