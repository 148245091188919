import React, { useState } from "react";
import "./Miata.scss";
import firebase from "../../config/firebase";
import { useHistory } from "react-router-dom";

function Miata() {
  const [type, setType] = useState("none");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  let history = useHistory();

  const handleChange = (e) => setType(e.target.value);

  const handleUrlChange = (e) => setUrl(e.target.value);

  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const submitForm = async () => {
    try {
      if (type !== "none" && url) {
        beginUpload();
        const db = firebase.firestore();
        await db.collection(type).add({
          url,
          description,
          order: 0,
        });
        resetForm();
        setSuccess("Success");
      } else {
        setSuccess("");
        setError("Fill out at least type and link");
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const beginUpload = () => {
    setLoading(true);
    setError("");
    setSuccess("");
  };

  const resetForm = () => {
    setLoading(false);
    setError("");
    setUrl("");
    setDescription("");
    setType("none");
  };

  const signOut = () => {
    localStorage.removeItem("auth");
    history.push("/narratives");
  };

  return (
    <div className="miataContainer">
      <p>Choose a type*</p>

      <select value={type} onChange={handleChange}>
        <option disabled value="none">
          {" "}
          -- select an option --{" "}
        </option>
        <option value="narratives">Narratives</option>
        <option value="musicvideos">Music Videos</option>
        <option value="documentary">Documentary</option>
      </select>

      <p>Video Link*</p>
      <textarea value={url} onChange={handleUrlChange} />

      <p>Description</p>
      <textarea value={description} onChange={handleDescriptionChange} />
      <br />
      <button onClick={submitForm}>Submit</button>
      <button onClick={signOut}>Sign Out</button>

      {isLoading && <div className="loader"></div>}

      <p className="error">{error}</p>
      <p className="success">{success}</p>
    </div>
  );
}

export default Miata;
