import React from "react";
import "./App.scss";
import Routing from './config/Routing'

function App() {
  return (
    <Routing />
  );
}
export default App;
