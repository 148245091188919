import React from "react";
import { useLocation } from "react-router-dom";
import {
  FaLinkedinIn,
  FaInstagram,
  FaEnvelope,
  FaCamera,
} from "react-icons/fa";
import "./Footer.scss";

function Footer() {
  const location = useLocation();
  const { pathname } = location;
  const showFooter = pathname !== "/";
  return (
    <>
      {showFooter && (
        <div className="footer">
          <div className="contact">
            <div className="contact-items">
              <a
                href="http://instagram.com/jocelyngallee"
                className="link-icon"
              >
                <FaInstagram className="link-icon" size={20} />
              </a>
              <a
                href="http://www.linkedin.com/in/jocelyngallegos"
                className="link-icon"
              >
                <FaLinkedinIn className="link-icon" size={20} />
              </a>
              {/* <a href="https://vimeo.com/jocelyngallegos" className="link-icon">
                <FaVimeoV className="link-icon" size={20} />
              </a> */}
              <a
                href="mailto:jocelyngallegos628@gmail.com"
                className="link-icon"
              >
                <FaEnvelope className="link-icon" size={20} />
              </a>
              <a
                href="https://kit.co/jocelyngallegos/jocelyn-gallegos-dp-kit"
                className="link-icon"
              >
                <FaCamera className="link-icon" size={20} title="My Kit!" />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
