import React from "react";
import { Link } from "react-router-dom";
import "./Landing.scss";

function Landing() {
  return (
    <div className="landing">
      <video id="landingVideo" autoPlay muted loop>
        <source
          src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/Jocelyn%20Gallegos%20Website%20Landing%20Page%20Video%20COMPRESSED.mp4?alt=media"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <Link className="landing-name" to="/reel">
          <h2>Jocelyn Gallegos</h2>
        </Link>
      </div>
    </div>
  );
}

export default Landing;
