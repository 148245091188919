import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import Feature from "../../components/Feature/Feature";
import "./Video.scss";
import Laurels from "../../components/Laurels/Laurels";
import firebase from "../../config/firebase";
import reel from "../../assets/reel.jpg";

const defaultVideoDetails = {
  url: "",
  description: "",
  isFeature: false,
};

function VideoGallery() {
  const location = useLocation();

  let [videoDetails, setVideoDetails] = useState(defaultVideoDetails);
  const [isReel, setIsReel] = useState(false);

  const getVideos = async () => {
    const db = firebase.firestore();
    const page = location.pathname.replace("/", "");

    try {
      const querySnapshot = await db.collection(page).get();
      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        setVideoDetails(data);
        console.log(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const page = location.pathname.replace("/", "");
    if (page === "reel") {
      setIsReel(true);
      getVideos();
    } else {
      let { item } = location.state;
      setVideoDetails(item);
    }
  }, [location.pathname]);

  function newlineText(text) {
    const newText = text.split("&&").map((str, i) => (
      <p key={i} className={`${i === 0 && "fakey ital"} fakey`}>
        {str}
      </p>
    ));

    return newText;
  }

  return (
    <div className="videoPageContainer">
      <div className="center">
        {videoDetails.url && !isReel ? (
          <Video url={videoDetails.url} />
        ) : (
          <video id="reelVideo" controls poster={reel}>
            <source
              src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/JocelynGallegos_Reel_Final_2022.mp4?alt=media"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {videoDetails.name && <Feature name={videoDetails.name} />}
      <div className="videoDescription">
        {newlineText(videoDetails.description)}
      </div>
      {videoDetails.hasLaurels && <Laurels name={videoDetails.name} />}
    </div>
  );
}

const Video = ({ url }) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        width="100%"
        height="100%"
        controls={true}
        className="react-player"
        url={url}
      />
    </div>
  );
};

export default VideoGallery;
