import React from "react";
import "./Commercial.scss";

// const customThumbnails = {
//   buzzReel,
// };

function Commercial() {
  return (
    <div className="commercialContainer">
      <div className="comSection" style={{ marginBottom: "5rem" }}>
        <p className="cTitle">BUZZFEED LA - STUDIO LIGHTING</p>
        <div className="cVideos">
          <Video
            className="cFeature"
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/StudioLightingReelThumb.jpg?alt=media"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/Jocelyn%20Gallegos%20Studio%20Lighting%20Reel%20COMPRESSED.mp4?alt=media"
          />
        </div>
      </div>
      <div className="comSection" style={{ marginBottom: "5rem" }}>
        <p className="cTitle">COFFEE FOR SASQUATCH - VIDEOGRAPHY</p>
        <div className="cVideos">
          <Video
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/1.%20CherrySage.jpg?alt=media"
            className="cVid"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/04_CherrySage_V1.mp4?alt=media"
          />
          <Video
            className="cVid"
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/2.%203%20Ways%20to%20Affogato.png"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/CFS_3Affogatos_V1.mp4?alt=media"
          />
          <Video
            className="cVid"
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/3.%20OrangeLatte.jpg?alt=media"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/CFS_OrangeLatte_V2.mp4?alt=media"
          />
          <Video
            className="cVid"
            poster="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/4.%20ClaireInterview.jpg?alt=media"
            src="https://firebasestorage.googleapis.com/v0/b/jocelyns-portfolio.appspot.com/o/CFS_ClaireInterview_FINALwMUSIC.mp4?alt=media"
          />
        </div>
      </div>
    </div>
  );
}

const Video = ({ src, ...props }) => (
  <video controls {...props}>
    <source src={src} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
);

export default Commercial;
