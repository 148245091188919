import React from "react";

import "./Feature.scss";
import featureImage from "../../assets/feature/1st.jpg";
import second from "../../assets/feature/2nd.jpg";
import third from "../../assets/feature/3rd.jpg";
import fourth from "../../assets/feature/4th.jpg";
import fifth from "../../assets/feature/5th.jpg";

import bamboo2 from "../../assets/bamboo/2.jpg";
import bamboo22 from "../../assets/bamboo/22.jpg";
import bamboo23 from "../../assets/bamboo/23.jpg";
import bamboo24 from "../../assets/bamboo/24.jpg";
import bamboo28 from "../../assets/bamboo/28.jpg";

import h2bh5 from "../../assets/h2bh/5-H2BH.jpg";
import h2bh6 from "../../assets/h2bh/6-H2BH.jpg";
import h2bh19 from "../../assets/h2bh/19-H2BH.jpg";
import h2bh32 from "../../assets/h2bh/32-H2BH.jpg";
import h2bh33 from "../../assets/h2bh/33-H2BH.jpg";

const images = {
  cork: [featureImage, second, third, fourth, fifth],
  bamboo: [bamboo2, bamboo22, bamboo23, bamboo24, bamboo28],
  happy: [h2bh5, h2bh6, h2bh19, h2bh32, h2bh33],
};

function Image({ item, onClick }) {
  return (
    <div className="list-image-container">
      <img
        className={"list-image"}
        src={item.thumbnail}
        alt="Italian Trulli"
        onClick={onClick}
      />
    </div>
  );
}

function Feature({ name }) {
  return (
    <div className="feature-container">
      <div className="list-image-grid">
        {images[name].map((img, i) => (
          <Image key={i} item={{ thumbnail: img }} />
        ))}
      </div>
    </div>
  );
}

export default Feature;
