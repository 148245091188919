import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import firebase from "../../config/firebase";
import corkThumbnail from "../../assets/feature/1st.jpg";
import h2bhThumbnail from "../../assets/h2bh/27-H2BH.jpg";
import bambooThumbnail from "../../assets/bamboo/34.jpg";
import fronteras from "../../assets/fronteras/thumbnail.jpg";
import "./VideoGallery.scss";

const customThumbnails = {
  fronteras,
  cork: corkThumbnail,
  happy: h2bhThumbnail,
  bamboo: bambooThumbnail,
};

const VideoGallery = ({ page }) => {
  let history = useHistory();
  const location = useLocation();
  const [videos, setVideos] = useState([{}]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getVideos();
  }, [location]);

  const getVideos = async () => {
    let videoTemp = [];
    const page = location.pathname.replace("/", "");
    const db = firebase.firestore();
    try {
      setLoading(true);
      const querySnapshot = await db
        .collection(page)
        .orderBy("order", "asc")
        .get();
      for (const doc of querySnapshot.docs) {
        // doc.data() is never undefined for query doc snapshots
        const { id } = doc;
        const data = doc.data();
        if (id) {
          const thumbnail = data.customThumbnail
            ? customThumbnails[data.name]
            : await getThumbnailImage(data.url);
          if (data.isActive) videoTemp.push({ id, thumbnail, ...data });
        }
      }
      setLoading(false);
      setVideos(videoTemp);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getThumbnailImage = async (url) => {
    if (url.includes("youtu")) {
      const videoId = getYouTubeId(url);
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    } else if (url.includes("vimeo")) {
      return await getVimeoThumbnail(url);
    }
  };

  const getVimeoThumbnail = async (url) => {
    try {
      const vimeoUrl = "https://vimeo.com/api/oembed.json?url=" + url;
      const response = await axios.get(vimeoUrl);
      const { data } = response;
      return data.thumbnail_url;
    } catch (e) {
      console.log(e);
    }
  };

  const getYouTubeId = (url) => {
    var ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  };

  const goToVideo = (item) => {
    history.push({
      pathname: `/video`,
      state: { item },
    });
  };

  return (
    <div className="container">
      <div className="grid">
        {videos.map((item, i) => (
          <Image key={i} onClick={() => goToVideo(item)} item={item} />
        ))}
      </div>
    </div>
  );
};

function Image({ item, onClick, feature = false }) {
  return (
    <div
      onClick={onClick}
      className={feature ? "feature-container" : "imageContainer"}
    >
      <img
        className={feature ? "feature-image" : "image"}
        src={item.thumbnail}
        alt="Video Thumbnail"
      />
      <div className="img__description_layer">
        <p className="img__description">{item.title}</p>
      </div>
    </div>
  );
}

export default VideoGallery;
